import { doOncePerVisit, MrModalDialog } from '@mrhenry/wp--mr-interactive';

const doOncePerVisit2 = ( x: string, y: () => void ) => {
	if ( window.location.href.includes( 'wp.test' ) ) {
		y();

		return;
	}

	doOncePerVisit( x, y );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare global {
	interface Window {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		jQuery: any;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		fnames: Array<any>;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		ftypes: Array<any>;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		$mcj: any;
	}
}

class MrPopup extends MrModalDialog {
	#hasRendered = false;

	constructor() {
		// If you define a constructor, always call super() first!
		// This is specific to CE and required by the spec.
		super();
	}

	async loadMailchimpScripts(): Promise<void> {
		return new Promise( ( resolve, reject ) => {
			const scriptTag = document.createElement( 'script' ) as HTMLScriptElement;

			scriptTag.onload = () => {
				resolve();
			};

			scriptTag.onerror = () => {
				reject( new Error( 'Mailchimp script failed to load.' ) );
			};

			scriptTag.setAttribute( 'defer', '' );
			scriptTag.setAttribute( 'async', '' );

			scriptTag.defer = true;
			scriptTag.async = true;

			scriptTag.src = 'https://s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js';

			document.head.append( scriptTag );
		} );
	}

	override connectedCallback() {
		super.connectedCallback();

		this.getContent();
	}

	async getContent() {
		try {
			let preview = false;
			if ( this.dataset.preview && '1' === this.dataset.preview ) {
				preview = true;
			}

			const resp = await fetch( `/wp-json/popup/general.json?preview=${preview}&lang=${document.documentElement.getAttribute( 'lang' ) ?? 'en'}` );
			const popupResponse = await resp.json();
			if ( !popupResponse || !popupResponse.html ) {
				return;
			}

			// open popup only if no other other overlay is opened
			if ( document.body.hasAttribute( 'has-open-overlay' ) ) {
				return;
			}

			setTimeout( () => {
				doOncePerVisit2( popupResponse.id, () => {
					if ( !this.#hasRendered ) {
						// add content
						this.innerHTML = popupResponse.html + this.innerHTML;
						if ( 'undefined' !== typeof self.jQuery ) { // mc-validate script gets blocked in china by the great firewall
							// eslint-disable-next-line @typescript-eslint/no-unused-vars
							( function( $ ) {
								self.fnames = [];
								self.ftypes = [];

								self.fnames[1] = 'FNAME';
								self.ftypes[1] = 'text';
								self.fnames[2] = 'LNAME';
								self.ftypes[2] = 'text';
								self.fnames[0] = 'EMAIL';
								self.ftypes[0] = 'email';
								self.fnames[3] = 'PROF';
								self.ftypes[3] = 'text';
							}( self.jQuery ) );

							self.$mcj = self.jQuery.noConflict( true );
						}
						this.#hasRendered = true;
					}

					setTimeout( () => {
						this.updateState( 'open' );
					}, 1000 );
				} );
			}, 100 );
			// }, 4000 );
		} catch ( err ) {
			console.warn( err ); // do nothing

			return;
		}
	}

	override async willOpen(): Promise<void> {
		await this.loadMailchimpScripts(); // This can error, going to ignore for now.

		document.body.setAttribute( 'has-open-overlay', '' );

		await super.willOpen();
	}

	override async willClose(): Promise<void> {
		document.body.removeAttribute( 'has-open-overlay' );

		await super.willClose();
	}

	override openAnimations(): Array<KeyframeEffect> {
		if ( !( 'KeyframeEffect' in window ) ) {
			return [];
		}

		return [
			new KeyframeEffect(
				this,
				[
					{
						opacity: 0,
					},
					{
						opacity: 1,
					},
				],
				{
					duration: 128,
					easing: 'ease-in-out',
					fill: 'forwards',
				}
			),
		];
	}

	override closeAnimations(): Array<KeyframeEffect> {
		if ( !( 'KeyframeEffect' in window ) ) {
			return [];
		}

		return [
			new KeyframeEffect(
				this,
				[
					{
						opacity: 1,
					},
					{
						opacity: 0,
					},
				],
				{
					delay: 128,
					duration: 128,
					easing: 'ease-in-out',
					fill: 'forwards',
				}
			),
		];
	}
}

customElements.define( 'mr-popup', MrPopup );
