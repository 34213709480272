import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

defineCustomElement( 'mr-video-embed-player', {
	attributes: [
		{
			attribute: 'video-iframe',
			type: 'string',
		},
	],
	controller: class extends BaseController {
		bind() {
			this.on( 'click .js-video-embed-player-poster', () => {
				this.swap();
			} );
		}

		swap() {
			const video = this.el.getAttribute( 'video-iframe' );
			const wrapper = this.el.querySelector( '.js-video-embed-player-wrapper' );

			if ( !video || !wrapper ) {
				return;
			}

			try {
				wrapper.innerHTML = JSON.parse( video );
				this.el.classList.add( 'is-playing' );
			} catch ( err ) {
				console.warn( err );
			}
		}
	},
} );
