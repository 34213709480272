/*
 * Mr Window Watcher 🖥👀
 */

import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

defineCustomElement( 'mr-window-watcher', {
	attributes: [],
	controller: class extends BaseController {
		bind() {
			// [B]
			let resizeThrottle = false;

			this.on( 'resize', () => {
				if ( resizeThrottle ) {
					return;
				}

				resizeThrottle = true;

				setTimeout( () => {
					resizeThrottle = false;

					this.el.dispatchEvent( new CustomEvent( 'mr-window-watcher:resize', {
						bubbles: true,
						cancelable: true,
					} ) );
				}, 240 );
			}, window, {
				passive: true,
			} );
		}
	},
} );
