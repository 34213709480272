
function toggleHandler() {
	const filterProducts = document.getElementById( 'filter-products' );
	if ( !filterProducts ) {
		return;
	}

	if ( filterProducts.hasAttribute( 'show-filters' ) ) {
		filterProducts.removeAttribute( 'show-filters' );
	} else {
		filterProducts.setAttribute( 'show-filters', '' );
	}

}

function initFilterProducts() {
	const filterProducts = document.getElementById( 'filter-products' );
	if ( !filterProducts ) {
		return;
	}

	const filterProductsToggle = filterProducts.querySelector( 'button[filter-products-toggle]' );
	if ( !filterProductsToggle ) {
		return;
	}

	filterProductsToggle.removeEventListener( 'click', toggleHandler );
	filterProductsToggle.addEventListener( 'click', toggleHandler );
}

initFilterProducts();
requestAnimationFrame( () => {
	initFilterProducts();
} );
window.addEventListener( 'load', () => {
	initFilterProducts();
} );
