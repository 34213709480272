import '@mrhenry/wp--bugsnag-config';

import { initMenuButtons } from '@mrhenry/wp--mr-interactive';

initMenuButtons();

import '@mrhenry/wp--autoplay-in-view';

import './modules/accordion';
import './modules/gallery-a';
import './modules/gallery-b';
import './modules/input-sink';
import './modules/popup';
import './modules/video-embed-player';
import './modules/window-watcher';
import './modules/filter-accordion';
import './modules/filter-products';
