/*
 * Adds next and previous button enhancement (click to scroll)
 *
 */

const selectors = {
	triggerPrevious: '.js-gallery-b__trigger-previous',
	triggerNext: '.js-gallery-b__trigger-next',
	track: '.js-gallery-b__track',
	item: '.js-gallery-b__item',
};

import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

defineCustomElement( 'mr-gallery-b', {
	attributes: [
		{
			attribute: 'current',
			type: 'int',
		},
	],
	controller: class extends BaseController {
		init() {
			this.items = Array.from( this.el.querySelectorAll( selectors.item ) );

			this.triggerPrevious = this.el.querySelector( selectors.triggerPrevious );
			this.triggerNext = this.el.querySelector( selectors.triggerNext );
			this.track = this.el.querySelector( selectors.track );

			if ( !this.current ) {
				this.current = 0;
			}

			this.animating = false;
		}

		render() {
			if ( 1 < this.items.length && this.track ) {
				if ( !this.el.classList.contains( 'has-rendered' ) ) {
					this.el.classList.add( 'has-rendered' );
				}

				let translateX = '';

				if ( window.matchMedia( '(min-width: 1440px)' ).matches ) {
					translateX = `translateX(${this.current * -73}%) translateX(-8.5%)${this.needsExtraTranslateX( -17 )}`;
				} else if ( window.matchMedia( '(min-width: 1280px)' ).matches ) {
					translateX = `translateX(${this.current * -80}%) translateX(-10%)${this.needsExtraTranslateX( -20 )}`;
				} else if ( window.matchMedia( '(min-width: 768px)' ).matches ) {
					translateX = `translateX(${this.current * -88}%) translateX(-6%)${this.needsExtraTranslateX( -12 )}`;
				}

				window.requestAnimationFrame( () => {
					this.track.style.transform = `${translateX} translateZ(0)`;
				} );

				if ( 0 === this.current ) {
					this.triggerPrevious.classList.remove( 'gallery-b__arrow--active' );
				} else {
					this.triggerPrevious.classList.add( 'gallery-b__arrow--active' );
				}

				if ( this.current === ( this.items.length - 1 ) ) {
					this.triggerNext.classList.remove( 'gallery-b__arrow--active' );
				} else {
					this.triggerNext.classList.add( 'gallery-b__arrow--active' );
				}
			}
		}

		needsExtraTranslateX( nudge ) {
			if ( 0 < this.current ) {
				return ` translateX(${this.current * nudge}%)`;
			}

			return '';
		}

		animatingListener() {
			this.track.addEventListener( 'transitionend', this.animationHandler );
			this.animating = true;

			// fallback for not transitionend browsers
			this.fallbackTimeout = setTimeout( () => {
				if ( this.animating ) {
					this.animationComplete();
				}
			}, 800 );
		}

		animationHandler( e ) {
			if ( e.target === this.track ) {
				this.animationComplete();
			}
		}

		animationComplete() {
			clearTimeout( this.fallbackTimeout );
			this.animating = false;
			this.removeEventListener( 'transitionend', this.exitedAnimationHandler );
		}

		bind() {
			this.on( 'click', ( e ) => {
				e.preventDefault();

				if ( !this.animating ) {
					this.previous();
				}
			}, this.triggerPrevious );

			this.on( 'click', ( e ) => {
				e.preventDefault();

				if ( !this.animating ) {
					this.next();
				}
			}, this.triggerNext );

			const touch = {
				startX: 0,
				threshold: 100,
				tresholdTime: 100,
				startTime: 0,
			};

			this.on( 'touchstart', ( e ) => {
				touch.startX = e.changedTouches[0].screenX;
				touch.startTime = new Date().getTime();
			}, null, {
				passive: true,
			} );

			this.on( 'touchend', ( e ) => {
				const distX = e.changedTouches[0].screenX - touch.startX;

				// Too fast for a drag -> handle as next()
				if ( new Date().getTime() - touch.startTime < touch.tresholdTime ) {
					return;
				}

				// Not enough movement for a drag -> handle as next()
				if ( Math.abs( distX ) < touch.threshold ) {
					return;
				}

				if ( 0 > distX ) {
					this.next();

					return;
				}

				this.previous();

			}, null, {
				passive: true,
			} );

			this.on( 'mr-window-watcher:resize', () => {
				// Reset some stuff on windows resize
				this.current = 0;
				this.render();
			}, window );
		}

		set current( to ) {
			let parsed = parseInt( to, 10 );

			if ( parsed === this.current ) {
				return;
			}

			const max = this.items.length;

			// If we're at the last slide and navigated 'Next'
			if ( parsed >= max ) {
				parsed = max - 1;
			}

			// If we're at the first slide and navigated 'Previous'
			if ( 0 > parsed ) {
				parsed = 0;
			}

			this.el.setAttribute( 'current', parsed );

			this.render();
		}

		previous() {
			this.current -= 1;
		}

		next() {
			this.current += 1;
		}
	},
} );
