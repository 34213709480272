const hasAccessToLocalStorage = ( (): boolean => {
	try {
		window.localStorage.getItem( 'mrh:test:localStorage:access' );
	} catch ( e ) {
		return false;
	}

	return true;
} )();

const hasAccessToSessionStorage = ( ():boolean => {
	try {
		window.sessionStorage.getItem( 'mrh:test:sessionStorage:access' );
	} catch ( e ) {
		return false;
	}

	return true;
} )();

function availableStore() {
	if ( hasAccessToLocalStorage ) {
		return localStorage;
	}

	if ( hasAccessToSessionStorage ) {
		return sessionStorage;
	}

	return null;
}

function storageKey( group: string, key: string ):string {
	return `mrh:do-once-per:${group}:${key}`;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function doOncePerYear( key: string, doCallback: () => void = () => {}, skipCallback: () => void = () => {} ): void {
	const year = 365 * 24 * 60 * 60 * 1000;

	oncePerWithFixedDuration( 'year:', year, key, doCallback, skipCallback );

	return;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function doOncePerVisit( key: string, doCallback: () => void = () => {}, skipCallback: () => void = () => {} ): void {
	const hours36 = 36 * 60 * 60 * 1000;

	oncePerWithExtendingDuration( 'visit:', hours36, key, doCallback, skipCallback );

	return;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function doOnceEver( key: string, doCallback: () => void = () => {}, skipCallback: () => void = () => {} ): void {
	const year = 365 * 24 * 60 * 60 * 1000;

	oncePerWithExtendingDuration( 'ever:', year, key, doCallback, skipCallback );

	return;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
function oncePerWithFixedDuration( group: string, duration: number, key: string, doCallback: () => void = () => {}, skipCallback: () => void = () => {} ): void {
	const store = availableStore();

	// No storage options, bail!
	if ( !store ) {
		skipCallback();

		return;
	}

	let visitedDate :Date|null = null;

	const visitedDateStr = store.getItem( storageKey( group, key ) );
	if ( visitedDateStr ) {
		visitedDate = dateFromJSONString( visitedDateStr );
	}

	// If it is not after xTime, we do not do the thing.
	if ( visitedDate && ( ( new Date().valueOf() ) - visitedDate.valueOf() ) < duration ) {
		skipCallback();

		return;
	}

	// Set visitedDate to now
	// To users this feels as if the action is not run again as long as they stay on the site.
	try {
		store.setItem( storageKey( group, key ), JSON.stringify( new Date() ) );
	} catch ( e ) {
		// We already checked for storage access and did not expect errors here.
		console.warn( e );
	}

	// Do the thing!
	doCallback();
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
function oncePerWithExtendingDuration( group: string, duration: number, key: string, doCallback: () => void = () => {}, skipCallback: () => void = () => {} ): void {
	// No access to localStorage, bail!
	if ( !hasAccessToLocalStorage ) {
		skipCallback();

		return;
	}

	let visitedDate :Date|null = null;

	const visitedDateStr = localStorage.getItem( storageKey( group, key ) );
	if ( visitedDateStr ) {
		visitedDate = dateFromJSONString( visitedDateStr );
	}

	// If it is not after xTime, we do not do the thing.
	let extending = false;
	if ( visitedDate && ( ( new Date().valueOf() ) - visitedDate.valueOf() ) < duration ) {
		extending = true;
		skipCallback();
	}

	// Set visitedDate to now
	// To users this feels as if the action is not run again as long as they stay on the site.
	try {
		localStorage.setItem( storageKey( group, key ), JSON.stringify( new Date() ) );
	} catch ( e ) {
		// We already checked for storage access and did not expect errors here.
		console.warn( e );
	}

	// Do the thing!
	if ( !extending ) {
		doCallback();
	}
}

// Having a Date or null is goed enough here
function dateFromJSONString( json: string ): Date|null {
	try {
		const parsed = JSON.parse( json );

		return new Date( parsed );
	} catch ( err ) {
		console.warn( err );

		return null;
	}
}
