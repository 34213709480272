import { MrAccordion } from '@mrhenry/wp--mr-interactive';

class MrFilterAccordion extends MrAccordion {
	constructor() {
		super();
	}

	override handleToggleEvent( trigger: Element, onlyOpen: boolean ): boolean {
		super.handleToggleEvent( trigger, onlyOpen );

		const filterStorage = window.sessionStorage;
		if ( !filterStorage ) {
			return true;
		}

		filterStorage.setItem( trigger.id, trigger.getAttribute( 'aria-expanded' ) ?? 'false' );

		return true;
	}
}

customElements.define( 'mr-filter-accordion', MrFilterAccordion );

/**
 * Check user selected panel states for the accordion items
 *
 * @returns void
 */
function initFilterAccordion() {
	let filterStorage;
	try {
		filterStorage = window.sessionStorage;
	} catch {}
	if ( !filterStorage ) {
		return;
	}

	// search panel
	const searchFilterValue = filterStorage.getItem( 'filter-products-search-trigger' );
	if ( searchFilterValue ) {
		const searchFilter = document.getElementById( 'filter-products-search-trigger' );

		if ( searchFilter ) {
			searchFilter.setAttribute( 'aria-expanded', searchFilterValue );

			const searchFilterPanel = document.getElementById( 'filter-products-search-panel' );
			if ( searchFilterPanel ) {
				if ( 'true' === searchFilterValue ) {
					searchFilterPanel.removeAttribute( 'hidden' );
					searchFilterPanel.inert = false;
				} else {
					searchFilterPanel.setAttribute( 'hidden', 'until-found' );
					searchFilterPanel.inert = true;
				}
			}
		}
	}

	// colour panel
	const colourFilterValue = filterStorage.getItem( 'filter-products-colour-trigger' );
	if ( colourFilterValue ) {
		const colourFilter = document.getElementById( 'filter-products-colour-trigger' );

		if ( colourFilter ) {
			colourFilter.setAttribute( 'aria-expanded', colourFilterValue );

			const colourFilterPanel = document.getElementById( 'filter-products-colour-panel' );
			if ( colourFilterPanel ) {
				if ( 'true' === colourFilterValue ) {
					colourFilterPanel.removeAttribute( 'hidden' );
					colourFilterPanel.inert = false;
				} else {
					colourFilterPanel.setAttribute( 'hidden', 'until-found' );
					colourFilterPanel.inert = true;
				}
			}
		}
	}

	// compositions panel
	const compositionFilterValue = filterStorage.getItem( 'filter-products-composition-trigger' );
	if ( compositionFilterValue ) {
		const compositionFilter = document.getElementById( 'filter-products-composition-trigger' );

		if ( compositionFilter ) {
			compositionFilter.setAttribute( 'aria-expanded', compositionFilterValue );

			const compositionFilterPanel = document.getElementById( 'filter-products-composition-panel' );
			if ( compositionFilterPanel ) {
				if ( 'true' === compositionFilterValue ) {
					compositionFilterPanel.removeAttribute( 'hidden' );
					compositionFilterPanel.inert = false;
				} else {
					compositionFilterPanel.setAttribute( 'hidden', 'until-found' );
					compositionFilterPanel.inert = true;
				}
			}
		}
	}

}

requestAnimationFrame( () => {
	initFilterAccordion();
} );
window.addEventListener( 'DOMContentLoaded', () => {
	initFilterAccordion();
} );
window.addEventListener( 'load', () => {
	initFilterAccordion();
} );
