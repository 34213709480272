( function() {
	let timeout: number | null = null;

	const getNextToggle = ( toggles: NodeListOf<HTMLInputElement> ) => {
		const currentElementIndex = Array.from( toggles ).findIndex( ( el ) => {
			return el.checked;
		} );

		let nextElementIndex = currentElementIndex + 1;
		if ( -1 === currentElementIndex || currentElementIndex >= ( toggles.length - 1 ) ) {
			nextElementIndex = 0;
		}

		return toggles[nextElementIndex];
	};

	const nextSlide = ( toggles: NodeListOf<HTMLInputElement> ) => {
		const nextToggle = getNextToggle( toggles );

		if ( !nextToggle ) {
			return;
		}

		nextToggle.checked = true;
	};

	const nextSlides = () => {
		const galleriesA = document.querySelectorAll( '[gallery-a]' );
		if ( !galleriesA || 1 > galleriesA.length ) {
			return;
		}

		galleriesA.forEach( ( gallery ) => {
			if ( !gallery || !( gallery instanceof HTMLElement ) ) {
				return;
			}

			const toggles = gallery.querySelectorAll( 'input[type="radio"][gallery-a-toggle]' ) as NodeListOf<HTMLInputElement>;
			if ( !toggles || 2 > toggles.length ) {
				return;
			}

			nextSlide( toggles );
		} );

		startCycle();
	};

	const startCycle = () => {
		timeout = window.setTimeout( () => {
			nextSlides();
		}, 5000 );
	};

	window.addEventListener( 'click', ( e: MouseEvent ) => {
		if ( !e.target || !( e.target instanceof HTMLElement ) || !e.target.hasAttribute( 'gallery-a-arrow' ) ) {
			return;
		}

		if ( !timeout ) {
			return;
		}

		window.clearTimeout( timeout );
		startCycle();
	} );

	startCycle();
}() );
